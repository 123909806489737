<template>
  <div class="gq-project-right-body">
    <div class="swiper-next swiper-btn" :class="'swiper-next' + num">
      <img src="@/assets/logos/swiperjiantou.png" alt="" />
    </div>
    <div class="swiper-prev swiper-btn" :class="'swiper-prev' + num">
      <img src="@/assets/logos/swiperjiantou.png" alt="" />
    </div>

    <div :class="'swiper-container-project' + num">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide gq-project-slide"
          v-for="(item, i) in projectData.projectList"
          :key="i + 3"
          @click="detail(item)"
        >
          <img :src="item.projectChart" alt="" />
        </div>

        <div
          class="swiper-slide swiper-slide-txt"
          :class="'swiper-slide-txt' + num"
        >
          <div class="gq-swiper-box">
            <div class="gq-project-More">More</div>
            <div class="gq-project-state">{{projectData.country? projectTitle[projectData.country].name:''}}</div>
            <div class="gq-project-txt">
              {{projectData.country?projectTitle[projectData.country].title:''}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
export default {
  props: {
    projectData: {
      type: Object,
  
    },
    projectTitle:{
      type: Object,
    }
  },
  data() {
    return {
      num: Math.floor(Math.random() * 10000),
      mySwiper: "",
    };
  },
  methods: {
    detail(data) {
      this.$emit("changeDetail", data);
    },
    init() {
      if (!this.projectData.projectList) return;
      let _that = this;
      this.$nextTick(() => {
        this.mySwiper = new Swiper(".swiper-container-project" + _that.num, {
          slidesPerView: 3,
          initialSlide: _that.projectData.projectList.length - 1,
          centeredSlides: true,
          navigation: {
            nextEl: ".swiper-next" + _that.num,
            prevEl: ".swiper-prev" + _that.num,
          },
        });
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
.gq-project-right-body {
  width: 100%;
  height: 518px;
  padding-top: 75px;
  overflow: hidden;
  //   padding-bottom: 90px;
  position: relative;
  .swiper-btn {
    top: 260px;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    position: absolute;
    z-index: 99;
    background: #81cbd8;
    text-align: center;
    cursor: pointer;
  }
  .swiper-next {
    left: 434px;
    img {
      width: 36px;
      height: 36px;
      margin-top: 14px;
    }
  }
  .swiper-prev {
    right: 282px;
    img {
      width: 36px;
      height: 36px;
      margin-top: 14px;
      transform: rotate(180deg);
    }
  }
}
.swiper-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.gq-project-slide {
  // width: 428px !important;
  // margin-left: 13px;
  // margin-right: 13px;
}
.swiper-slide-txt {
  // width: 540px !important;
  // background: red;
}
.swiper-slide {
  height: 428px;

  img {
    width: 454px;
    height: 428px;
  }
  .gq-swiper-box {
    // padding-left: 50px;
    padding-right: 50px;
    margin-top: 272px;
    width: 280px;
    margin-left: 140px;
    .gq-project-More {
      font-size: 23px;
      font-family: Helvetica;
      font-weight: normal;
      color: #638390;
      font-style: oblique;
    }
    .gq-project-state {
      font-size: 54px;
      font-weight: bold;
      line-height: 64px;
      color: #333333;
    }
    .gq-project-txt {
      font-size: 18px;
      font-weight: 300;
      line-height: 23px;
      color: #444444;
    }
  }
}
</style>